import React from "react";
import { ReactSVG } from "react-svg";
import { Link } from "gatsby";
import "./styles.scss";

const Links = ({ LinksArray, FooterLink }) => {
  return (
    <div className="Links">
      {LinksArray.map((link, index) => (
        <Link
          to={link.url}
          className="Links__item"
          key={`link-array-${index}`}>
          <span className="Links__label">{link.label}</span>
          <span className="Links__text">{link.text}</span>
          <ReactSVG
            src="/svg/arrow_right_24px.svg"
            className="Links__icon"
          />
        </Link>
      ))}

      {FooterLink && (
        <Link
          href={FooterLink.url}
          className="Links__footerLink">
          {FooterLink.text}
        </Link>
      )}
    </div>
  );
};

export default Links;
